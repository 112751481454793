import { useState } from "react";
import axios from "axios";
import adminAPI from "../../constants/admin-API";
import useToken from "../useToken";
import useCheckToken from "../useCheckToken";
interface RequestClubManagerType {
  pagination?: { no_per_page: number; page_no: number };
  club_manager_fullname?: string;
  club_manager_status?: "0" | "1" | "2" | "3" | null;
  club_manager_idcard_status?: "0" | "1" | "2" | "3" | null;
}
const useGetAllClubManagers = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  const token = useToken();
  const checkToken = useCheckToken();
  const getAllClubManagers = async (
    options?: RequestClubManagerType,
    callback?: any
  ) => {
    const formData = new FormData();
    if (options?.club_manager_fullname) {
      formData.append("club_manager_fullname", options.club_manager_fullname);
    }
    if (options?.club_manager_idcard_status) {
      formData.append(
        "club_manager_idcard_status",
        options.club_manager_idcard_status
      );
    }
    if (options?.club_manager_status) {
      formData.append("club_manager_status", options.club_manager_status);
    }
    if (options?.pagination) {
      formData.append("pagination", JSON.stringify(options.pagination));
    }

    try {
      setIsLoading(true);
      await axios
        .post(adminAPI.getAllClubs, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          checkToken(data.data.exception);
          setIsLoading(false);
          setResult(data);
          if (callback) {
            callback(data);
          }
          return data;
        });
    } catch (e: any) {
      setIsLoading(false);
      console.log(e);
      setError(e);
      return e;
    }
  };

  return {
    getAllClubManagers,
    result,
    isLoading,
    error,
  };
};
export default useGetAllClubManagers;
