import { createSlice } from "@reduxjs/toolkit";
import { NotificationTypeEnum } from "../../enums/MainEnums";

export interface NotificationInterface {
    id: string;
    type: string,
    message: string
}

export interface NotifiesInterface {
    notifies?: NotificationInterface
}

const initialState: NotifiesInterface = {
    notifies: undefined,
};

export const notifiesSlice = createSlice({
    name: "notifies",
    initialState,
    reducers: {
        showSuccess: (state, action) => {
            state.notifies = {
                id: new Date().getTime().toString() +
                    Math.floor(Math.random() * 1000000),
                message: action.payload,
                type: NotificationTypeEnum.Success
            }
        },
        showError: (state, action) => {

            state.notifies = {
                id: new Date().getTime().toString() +
                    Math.floor(Math.random() * 1000000),
                message: action.payload,
                type: NotificationTypeEnum.Error
            }
        }

    }
});

export const { showSuccess, showError } = notifiesSlice.actions;

export default notifiesSlice.reducer;

