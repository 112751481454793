import { toast, ToastContainer } from "react-toastify";
import { NotificationTypeEnum } from "../../enums/MainEnums";
import { useAppSelector } from "../../hooks/useReduxToolkit";
import { NotificationInterface } from "../../store/reducers/notifiesSlice";
import { MainReducerInterface } from "../../store/store";

const ToasterComponent = () => {
  const notification = useAppSelector(
    (state: MainReducerInterface) => state.notifies.notifies
  );

  const showToaster = (notification?: NotificationInterface) =>
    notification?.type === NotificationTypeEnum.Success
      ? toast.success(notification?.message, { toastId: "" })
      : NotificationTypeEnum.Error
      ? toast.error(notification?.message, { toastId: "" })
      : undefined;

  return (
    <>
      {notification && !toast.isActive(notification.id) && (
        <>
          {showToaster(notification)}
          <ToastContainer position="top-center" />
        </>
      )}
    </>
  );
};

export default ToasterComponent;
