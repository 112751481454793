import TabsList from "pages/admin/components/Tabs/TabsList";
import AcceptedTable from "./acceptedTable";
import PendingTable from "./pendingTable";
import { useState } from "react";
import RejectedTable from "./rejectedTable";

const PlayersTables = () => {
  const [tabId, setTabId] = useState<number>(0);
  return (
    <>
      <TabsList
        tabIndicatorSx={
          {
            // backgroundColor: "#081170",
          }
        }
        initialValue={tabId}
        tabs={[
          {
            children: <PendingTable />,
            title: "Pending players",
          },
          { children: <AcceptedTable />, title: "Accepted Players" },
          { children: <RejectedTable />, title: "Rejected Players" },
        ]}
      ></TabsList>
    </>
  );
};
export default PlayersTables;
