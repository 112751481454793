import axios, { InternalAxiosRequestConfig } from "axios";
import { HttpMethodEnum, UserTypeEnum } from "../enums/MainEnums";
import { store } from "store/store";
import { showError } from "store/reducers/notifiesSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useReduxToolkit";
import { setRequests } from "store/reducers/requestsSlice";

const service = axios.create();
const baseUrl = process.env.REACT_APP_BASE_URL;
const userType = localStorage.getItem("userType");

service.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

service.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    store.dispatch(showError("Network Error"));
    if (response?.status === 401) {
      window.location.href = "./login";
    }
  }
);
const getRequests = async () => {
  const token = getAccessToken();

  const response = await axios.post(
    userType === UserTypeEnum.Player
      ? "https://api.itackle.net/player/getCountPendingRequest"
      : "https://api.itackle.net/club/getCountPendingRequest",
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (+response?.data?.data?.count_pending_request > 0) {
    store.dispatch(
      setRequests({
        isNewRequest: true,
        requestsCount: response?.data?.data?.count_pending_request,
      })
    );
  } else {
    store.dispatch(
      setRequests({
        isNewRequest: false,
        requestsCount: response?.data?.data?.count_pending_request,
      })
    );
  }
};

export const Post = async (url: string, data: any) => {
  const form = data ? new FormData() : null;

  getRequests();

  if (form) {
    Object.keys(data).forEach((item) => {
      form.append(item, data[item]);
    });
  }
  try {
    const response = await service.request({
      method: HttpMethodEnum.Post,
      url: `${baseUrl}/${url}`,
      responseType: "json",
      data: form,
    });
    if (
      response?.data?.result ||
      (!response?.data?.result &&
        response?.data?.data?.message.includes("Not Completed"))
    ) {
      return response.data;
    } else {
      const message: string = response?.data?.data?.message;

      if (
        message?.toLocaleLowerCase().includes("token") ||
        message?.toLocaleLowerCase().includes("bearer")
      ) {
        localStorage.removeItem("tokenTackle");
        localStorage.removeItem("userType");
        store.dispatch(showError("Please login again"));
        window.location.replace("/");
      }
      store.dispatch(
        showError(response?.data?.data?.message || "Network Error")
      );
      return response.data;
    }
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const get = async (url: string) => {
  await service.request({
    method: HttpMethodEnum.Get,
    url: `${baseUrl}/${url}`,
    responseType: "json",
  });
};

export const getAccessToken = () => {
  const token = localStorage.getItem("tokenTackle") || null;
  return token;
};
