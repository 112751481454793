import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";

export default function useDevice() {
    return {
        isMobile: !useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), {
            noSsr: true,
        }),
        isNotMobile: useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), {
            noSsr: true,
        }),
        isBigScreen: useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
            noSsr: true,
        }),
    };
}