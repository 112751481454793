import { FC } from "react";
import "./Loading.css";

const Loading: FC = () => {
  return (
    <div className="w-full h-[80px] flex items-center justify-center mt-[150px] ">
      <div className="three-body">
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
        <div className="three-body__dot"></div>
      </div>
    </div>
  );
};
export default Loading;
