import TabsList from "pages/admin/components/Tabs/TabsList";
import AcceptedTable from "./acceptedTable";
// import PendingTable from "./pendingTable";
import { useState } from "react";
import IdCardStatusTables from "./id-card-status";
import StatusTables from "./status";
// import RejectedTable from "./rejectedTable";

const ClubManagersTables = () => {
  const [tabId, setTabId] = useState<number>(0);
  return (
    <>
      <TabsList
        initialValue={tabId}
        tabs={[
          {
            children: <IdCardStatusTables />,
            title: "Club Manager ID Card status",
          },
          {
            children: <StatusTables />,
            title: "Club Manager status",
          },
          { children: <AcceptedTable />, title: "Accepted Club Managers" },
        ]}
      ></TabsList>
    </>
  );
};
export default ClubManagersTables;
