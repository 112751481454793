export const returnResponseList = (response: any) => {
  const res = response.data.rows !== false ? response.data.rows : [];
  return res;
};

export const returnResponse = (response: any) => {
  const res = response?.data?.row !== false ? response?.data?.row : null;
  return res;
};
export const returnList = (response: any) => {
  const res = response && response !== false ? response : [];
  return res;
};

export const getUserType = () => {
  const userType = localStorage.getItem("userType");
  return userType;
};

export const validateEmail = (email: string): boolean => {
  var regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validMobile = (mobile: string): boolean => {
  const regex = /^(\+|00)[0-9]{1,3}[0-9]{3,14}$/;
  return regex.test(mobile);
};
