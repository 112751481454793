import { DataGrid } from "@mui/x-data-grid";
import PlayersTables from "./playersTables";
import TabsList from "pages/admin/components/Tabs/TabsList";
import { useEffect, useState } from "react";
import ClubManagersTables from "./clubManagersTable";
import useCheckToken from "pages/admin/hooks/useCheckToken";
const Home = () => {
  const [tabId, setTabId] = useState<number>(0);
  const checkToken = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  return (
    <>
      <TabsList
        tabsContainerSx={{
          backgroundColor: "#f9f9f9",
        }}
        tabIndicatorSx={{
          backgroundColor: "black",
        }}
        tabSx={{
          color: "black",
          "&.Mui-selected": {
            color: "black",
            fontWeight: "700",
          },
        }}
        tabs={[
          {
            children: <PlayersTables />,
            title: "Players",
          },
          {
            children: <ClubManagersTables />,
            title: "Club Managers",
          },
        ]}
        initialValue={tabId}
      ></TabsList>
    </>
  );
};
export default Home;
