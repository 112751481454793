import { configureStore } from "@reduxjs/toolkit";
import { NotifiesInterface } from "./reducers/notifiesSlice";
import notifiesReducer from "./reducers/notifiesSlice";
import requestsReducer, { RequestsDataType } from "./reducers/requestsSlice";

export interface MainReducerInterface {
  notifies: NotifiesInterface;
  requests: RequestsDataType;
}

export const store = configureStore<MainReducerInterface>({
  reducer: {
    notifies: notifiesReducer,
    requests: requestsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
