import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";

import Header from "./components/header/Header";
import ToasterComponent from "components/toaster/Toaster";
import Loading from "components/loading/Loading";
import { getUserType } from "core/helper";

const Club = React.lazy(() => import("./pages/club/Club"));
const Player = React.lazy(() => import("./pages/player/Player"));
const Home = React.lazy(() => import("./pages/home/Home"));
const Main = React.lazy(() => import("./pages/main/Main"));
const AboutUs = React.lazy(() => import("./pages/about-us/aboutUs"));
const ForgetPassword = React.lazy(
  () => import("./pages/forget-password/ForgetPassword")
);

const App = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <div className="overflow-auto min-h-screen flex flex-col">
          <Header />
          <div className="flex flex-col w-full mb-[60px] mt-[60px] md:mt-0 flex-grow">
            <ToasterComponent />
            <Routes>
              <Route path="player" element={<Player />} />
              <Route path="club" element={<Club />} />
              <Route path="/" element={<Home />} />
              <Route path="forget-password" element={<ForgetPassword />} />
              <Route path="main/*" element={<Main />} />
              <Route path="about-us" element={<AboutUs />} />
              {/* <Route path="/" element={<Navigate to={"/home"} />} /> */}
            </Routes>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export default App;
