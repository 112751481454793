import React, { FC, useState } from "react";
import SideBarMenu from "../sidebar/SideBarMenu";
import { Close } from "../../assets/svg";
import MenuIcon from "@mui/icons-material/Menu";
// import { BellIcon, UserIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import useDevice from "hooks/useDevice";
import { getAccessToken } from "core/helper.service";
import LogoutIcon from "@mui/icons-material/Logout";
import AuthService from "services/AuthService";
import { getUserType } from "core/helper";
import { UserTypeEnum } from "enums/MainEnums";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import logo from "../../assets/images/logo.svg";
import { useAppSelector } from "hooks/useReduxToolkit";
import { CircularProgress } from "@mui/material";

const Header = () => {
  const navigate = useNavigate();
  const device = useDevice();
  const { pathname, state } = useLocation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const requestsData = useAppSelector((state) => state.requests);

  const onHandleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  const changeUserRoute = () => {
    if (getUserType() === UserTypeEnum.Player) {
      // pathname !== "/main/player-profile" &&
      navigate("/main/player-profile", { state: null });
    } else {
      // pathname !== "/main/club-profile" &&
      navigate("/main/club-profile", { state: null });
    }
  };

  const changeRequestRoute = () => {
    if (getUserType() === UserTypeEnum.Player) {
      navigate("/main/requests-player");
    } else {
      navigate("/main/requests-club");
    }
  };

  const changeRoute = (route: string) => {
    navigate(route);
  };

  const logOut = async () => {
    setIsLoading(true);
    await AuthService.logOut();
    setIsLoading(false);
    localStorage.removeItem("tokenTackle");
    localStorage.removeItem("userType");
    navigate("/");
  };

  return (
    <header className="bg-[#2A2A2A] fixed md:relative text-white top-0 z-10 w-full min-h-[60px] flex">
      {device.isMobile ? (
        <div className="flex w-full flex-row items-center">
          <div className="flex justify-between w-[85%] flex-row items-center">
            <div className=" flex justify-center pl-5">
              {getAccessToken() && (
                <>
                  <span>
                    <PersonIcon
                      className={`mr-4 cursor-pointer ${
                        pathname.includes("profile") && !state
                          ? "text-yellow1"
                          : ""
                      }`}
                      width={26}
                      height={26}
                      onClick={() => changeUserRoute()}
                    />
                  </span>

                  <span className="relative">
                    <NotificationsIcon
                      width={26}
                      height={26}
                      className={` cursor-pointer ${
                        pathname.includes("requests") ? "text-yellow1" : ""
                      }`}
                      onClick={() => changeRequestRoute()}
                    />
                    {requestsData.isNewRequest && (
                      <span className="h-3 w-3 absolute bottom-0 right-0 bg-red-600 rounded-full text-[10px] flex items-center justify-center p-1">
                        {requestsData.requestsCount}
                      </span>
                    )}
                  </span>
                </>
              )}
            </div>
            <div
              className="w-[88%] text-[#02C39A] font-bold text-lg text-center flex items-center justify-center"
              onClick={() => navigate("/")}
            >
              <img className="h-[30px] mx-2" src={logo} alt="logo" />
              iTackle
            </div>
          </div>
          <div className="flex justify-center w-[15%]">
            <button
              className="text-gray-400 hover:text-white focus:outline-none"
              onClick={onHandleMenuToggle}
            >
              <svg viewBox="0 0 20 20" className="h-6 w-6 fill-current">
                {menuOpen ? <Close /> : <MenuIcon />}
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <div className="basis-1/3 flex flex-row px-8">
            {getAccessToken() && (
              <>
                <button disabled={isLoading} onClick={logOut}>
                  {isLoading ? (
                    <CircularProgress size="26px" />
                  ) : (
                    <LogoutIcon
                      className="cursor-pointer"
                      sx={{
                        height: "26px",
                        width: "26px",
                      }}
                    />
                  )}
                </button>
                <span className={"mr-2"}>
                  <PersonIcon
                    width={26}
                    height={26}
                    className={`mx-3 cursor-pointer ${
                      pathname.includes("profile") && !state
                        ? "text-yellow1"
                        : ""
                    }`}
                    onClick={() => changeUserRoute()}
                  />
                </span>
                <span className="relative">
                  <NotificationsIcon
                    className={`cursor-pointer ${
                      pathname.includes("requests") ? "text-yellow1" : ""
                    }`}
                    width={26}
                    height={26}
                    onClick={() => changeRequestRoute()}
                  />
                  {requestsData.isNewRequest && (
                    <span className="h-3 w-3 absolute bottom-0 right-0 bg-red-600 rounded-full text-[10px] flex items-center justify-center p-1">
                      {requestsData.requestsCount}
                    </span>
                  )}
                </span>
              </>
            )}
          </div>
          <div
            className="basis-1/2 text-lg font-semibold cursor-pointer text-primeBlue flex justify-center items-center"
            onClick={() => navigate("/")}
          >
            <img className="h-[30px] mx-2" src={logo} alt="logo" />
            iTackle
          </div>
          <nav className={`basis-1/2 flex justify-end mt-0`}>
            <ul className="text-gray-400 flex items-center">
              {/* {!getAccessToken() && (
                <>
                  <li className="my-0">
                    <a
                      className={
                        "inline-block px-4 py-2 mx-2 cursor-pointer " +
                        (pathname === "/club" && "text-yellow1")
                      }
                      onClick={() => changeRoute("/club")}
                    >
                      Club
                    </a>
                  </li>
                  <li className="my-0">
                    <a
                      className={
                        "inline-block px-4 py-2 mx-2 cursor-pointer " +
                        (pathname === "/player" && "text-yellow1")
                      }
                      onClick={() => changeRoute("/player")}
                    >
                      Player
                    </a>
                  </li>
                </>
              )} */}

              {getAccessToken() && (
                <>
                  <li className="my-0">
                    <a
                      className={
                        "inline-block px-4 py-2 mx-2 cursor-pointer  whitespace-nowrap " +
                        (pathname === "/main/clubs-list" && "text-yellow1")
                      }
                      onClick={() => changeRoute("/main/clubs-list")}
                    >
                      Clubs List
                    </a>
                  </li>
                  <li className="my-0">
                    <a
                      className={
                        "inline-block px-4 py-2 mx-2 cursor-pointer whitespace-nowrap " +
                        (pathname === "/main/players-list" && "text-yellow1")
                      }
                      onClick={() => changeRoute("/main/players-list")}
                    >
                      Players List
                    </a>
                  </li>
                </>
              )}
              <li className="my-2 md:my-0">
                <a
                  onClick={() => changeRoute("/about-us")}
                  className={
                    "inline-block px-4 py-2 mx-2 cursor-pointer whitespace-nowrap " +
                    (pathname === "/about-us" && "text-yellow1")
                  }
                >
                  About Us
                </a>
              </li>
              <li className="my-2 md:my-0">
                <a
                  className="block md:inline-block px-4 py-2 md:mx-2 cursor-pointer whitespace-nowrap "
                  href="https://www.goldensports.at/"
                  target="_blank"
                >
                  Golden Sports
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {/*<div className=" w-full flex justify-between items-center px-4 py-4 md:hidden">
         <div className="text-lg font-semibold md:hidden text-center  text-primeBlue">
          iTackle
        </div> 

      </div>*/}

      <SideBarMenu closeSideBar={onHandleMenuToggle} isOpen={menuOpen} />
    </header>
  );
};

export default Header;
