import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#02C39A",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 5,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:focus": {
            borderColor: "#02C39A",
          },
          height: 10,
        },
        root: {
          //   height: 41,
          minHeight: 41,
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#A8A8A8",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "&:hover": {
              backgroundColor: "#02C39A",
              color: "#FFF",
            },
            backgroundColor: "#02C39A",
            color: "#FFF",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&::before": {
            display: "none",
          },
          marginBottom: "12px",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15) ",
        },
      },
    },
  },
});
const MuiThemeProvide = (props: any) => {
  return (
    <>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </>
  );
};

export default MuiThemeProvide;
