import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface DeleteOptions {
  showConfirmDelete: boolean;
  id: string;
  email: string;
}
interface Props {
  isOpen: boolean;
  setDeleteOptions: Dispatch<SetStateAction<DeleteOptions>>;
  onConfirm?: any;
  onCancle?: any;
}

const DeleteModal = ({
  isOpen,
  onCancle,
  onConfirm,
  setDeleteOptions,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setDeleteOptions({
          email: "",
          id: "",
          showConfirmDelete: false,
        });
      }}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>Are you sure to delete ?</DialogContent>
      <DialogContent>
        <Button
          onClick={() => {
            setDeleteOptions({
              email: "",
              id: "",
              showConfirmDelete: false,
            });
            if (onConfirm) {
              onConfirm();
            }
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={() => {
            setDeleteOptions({
              email: "",
              id: "",
              showConfirmDelete: false,
            });
            if (onCancle) {
              onCancle();
            }
          }}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
