import { CircularProgress } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import useAcceptClubManagerIdCard from "pages/admin/hooks/api/useAcceptClubManagerIdCard";
import useAcceptPlayerIdCard from "pages/admin/hooks/api/useAcceptPlayerIdCard";
import useGetAllClubManagers from "pages/admin/hooks/api/useGetAllClubManager";
import useGetAllPlayer from "pages/admin/hooks/api/useGetAllPlayer";
import useRejectClubManagerIdCard from "pages/admin/hooks/api/useRejectClubManagerIdCard";
import useRejectPlayerIdCard from "pages/admin/hooks/api/useRejectPlayerIdCard";
import { useEffect, useState } from "react";

interface RowType {
  id: string;
  fisrtName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  idCard: string;
}

const PendingTable = () => {
  const [loadingList, setLoadingList] = useState<(string | number)[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const acceptClubManagerAction = async (params: GridRowParams) => {
    setLoadingList((d) => [...d, params.id]);
    await acceptClubManagerIdCard({ club_manager__id: `${params.id}` });
    setLoadingList((d) => d.filter((l) => l !== params.id));
  };
  const rejectClubManagerAction = async (params: GridRowParams) => {
    setLoadingList((d) => [...d, params.id]);
    await rejectClubManagerIdCard({ club_manager__id: `${params.id} ` });
    setLoadingList((d) => d.filter((l) => l !== params.id));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "fullName", headerName: "Full Name", width: 250 },
    { field: "clubName", headerName: "Club name", width: 150 },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },
    {
      field: "idCard",
      headerName: "ID Card",
      width: 160,
      renderCell: (params: GridRenderCellParams) => (
        <a href={params.value} target="_blank" className="underline">
          <img src={params.value} alt="" />
        </a>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 200,
      getActions: (params: GridRowParams) => [
        <button
          disabled={
            (rejectLoading || acceptLoading) && loadingList.includes(params.id)
          }
          onClick={() => acceptClubManagerAction(params)}
          className="border-2 w-[50%] min-w-[80px]  px-2 py-2 rounded-md border-green-600"
        >
          {(acceptLoading || rejectLoading) &&
          loadingList.includes(params.id) ? (
            <CircularProgress
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          ) : (
            "Accept"
          )}
        </button>,
        <button
          disabled={
            (rejectLoading || acceptLoading) && loadingList.includes(params.id)
          }
          onClick={() => rejectClubManagerAction(params)}
          className="border-2 w-[50%] min-w-[80px] px-2 py-2 rounded-md border-red-600"
        >
          {(rejectLoading || acceptLoading) &&
          loadingList.includes(params.id) ? (
            <CircularProgress
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          ) : (
            "Reject"
          )}
        </button>,
      ],
    },
  ];
  const {
    error: getPlayerError,
    getAllClubManagers,
    isLoading: getPlayerLoading,
    result,
  } = useGetAllClubManagers();

  const {
    error: acceptError,
    acceptClubManagerIdCard,
    isLoading: acceptLoading,
    result: acceptResult,
  } = useAcceptClubManagerIdCard();

  const {
    error: rejectError,
    rejectClubManagerIdCard,
    isLoading: rejectLoading,
    result: rejectResult,
  } = useRejectClubManagerIdCard();

  useEffect(() => {
    getAllClubManagers({
      club_manager_idcard_status: "1",
      club_manager_status: "1",
      pagination: {
        no_per_page: paginationModel.pageSize,
        page_no: paginationModel.page + 1,
      },
    });
  }, [rejectResult, acceptResult, paginationModel]);

  const rows: RowType[] = result?.data?.rows?.map(
    ({
      club_manager__id: id,
      club_manager_fullname: fullName,
      club_name: clubName,
      email,
      idcard_img: idCard,
    }: any) => {
      return {
        id,
        fullName,
        clubName,
        email,
        idCard,
      };
    }
  );

  return (
    <>
      <div className="overflow-auto h-full w-full">
        <DataGrid
          rows={rows || []}
          columns={columns}
          loading={getPlayerLoading}
          autoHeight
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={result?.data?.total_rows}
          pageSizeOptions={[5, 10, 15, 20]}
          onPaginationModelChange={setPaginationModel}
        />
      </div>
    </>
  );
};

export default PendingTable;
