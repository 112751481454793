import TabsList from "pages/admin/components/Tabs/TabsList";
// import PendingTable from "./pendingTable";
import { useState } from "react";
import PendingTable from "./pendingTable";
import RejectedTable from "./rejectedTable";
// import RejectedTable from "./rejectedTable";

const StatusTables = () => {
  const [tabId, setTabId] = useState<number>(0);
  return (
    <>
      <TabsList
        tabIndicatorSx={{
          backgroundColor: "#05c3f7",
        }}
        tabSx={{
          "&.Mui-selected": {
            color: "#05c3f7",
            fontWeight: "700",
          },
        }}
        initialValue={tabId}
        tabs={[
          { children: <PendingTable />, title: "Pending" },
          {
            children: <RejectedTable />,
            title: "rejected",
          },
        ]}
      ></TabsList>
    </>
  );
};
export default StatusTables;
