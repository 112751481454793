const baseUrl = process.env.REACT_APP_BASE_URL;

export default {
  login: `${baseUrl}/auth/login_admin`,
  getAllPlayers: `${baseUrl}/admin/getAllPlayer`,
  getAllClubs: `${baseUrl}/admin/getAllClubManagerTable`,
  acceptPlayerIdCard: `${baseUrl}/admin/acceptPlayerIdCard`,
  rejectPlayerIdCard: `${baseUrl}/admin/rejectPlayerIdCard`,
  acceptClubManager: `${baseUrl}/admin/acceptClubManager`,
  rejectClubManager: `${baseUrl}/admin/rejectClubManager`,
  acceptClubManagerIdCard: `${baseUrl}/admin/acceptClubManager_idcard`,
  rejectClubManagerIdCard: `${baseUrl}/admin/rejectClubManager_idcard`,
  deletePlayer: `${baseUrl}/admin/deletePlayer`,
  deleteClubManager: `${baseUrl}/admin/deleteClubManager`,
};
