export enum HttpMethodEnum {
    Post = "Post",
    Put = "Put",
    Get = "Get",
    Delete = "Delete"
}

export enum NotificationTypeEnum {
    Success = 'success',
    Error = 'error',
}

export enum UserTypeEnum {
    Player = "Player",
    Club = "ClubManager"
}