import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface RequestsDataType {
  isNewRequest: boolean;
  requestsCount: number | string;
}

const initialState: RequestsDataType = {
  isNewRequest: false,
  requestsCount: 0,
};

export const requestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setRequests: (state, action: PayloadAction<RequestsDataType>) => {
      state.isNewRequest = action.payload.isNewRequest;
      state.requestsCount = action.payload.requestsCount;
    },
  },
});

export const { setRequests } = requestSlice.actions;

export default requestSlice.reducer;
