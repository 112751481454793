import { Post } from "core/helper.service";
import {
  RegisterFormClubModel,
  RegisterPlayerRequestModel,
  UploadImagePlayerModel,
} from "model/RegistrationModel";

const AuthService = {
  registerPlayer: (data: RegisterPlayerRequestModel) => {
    return Post("auth/registerPlayer", data);
  },
  registerClubManager: (data: any) => {
    return Post("auth/registerClubManager", data);
  },
  logOut: () => {
    return Post("auth/logOut", null);
  },
  login: (data: any) => {
    return Post("auth/login", data);
  },
  resetPassword: (data: any) => {
    return Post("auth/resetPassword", data);
  },
  acceptResetPassword: (data: any) => {
    return Post("auth/acceptResetPassword", data);
  },
  uploadIdCardPlayer: (data: UploadImagePlayerModel) => {
    return Post("auth/uploadIdCard_player", data);
  },
  uploadIdCardClubManager: (data: any) => {
    return Post("auth/uploadIdCard_ClubManager", data);
  },
  sendRegisterVerificationCode: (data: any) => {
    return Post("auth/send_register_verification_code", data);
  },
  verificationRegister: (data: any) => {
    return Post("auth/verificationRegister", data);
  },
};

export default AuthService;
