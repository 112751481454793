import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import DeleteModal from "pages/admin/components/deleteModal";
import useAcceptPlayerIdCard from "pages/admin/hooks/api/useAcceptPlayerIdCard";
import useDeletePlayer from "pages/admin/hooks/api/useDeletePlayer";
import useGetAllPlayer from "pages/admin/hooks/api/useGetAllPlayer";
import useRejectPlayerIdCard from "pages/admin/hooks/api/useRejectPlayerIdCard";
import { useEffect, useState } from "react";

interface RowType {
  id: string;
  fisrtName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  idCard: string;
}

interface DeleteOptions {
  showConfirmDelete: boolean;
  id: string;
  email: string;
}

const RejectedTable = () => {
  const [loadingList, setLoadingList] = useState<(string | number)[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });
  const [deleteOptions, setDeleteOptions] = useState<DeleteOptions>({
    showConfirmDelete: false,
    email: "",
    id: "",
  });

  const acceptPlayerAction = async (params: GridRowParams) => {
    setLoadingList((d) => [...d, params.id]);
    await acceptPlayerIdCard({ player__id: `${params.id}` });
    setLoadingList((d) => d.filter((l) => l !== params.id));
  };
  // const rejectPlayerAction = async (params: GridRowParams) => {
  //   setLoadingList((d) => [...d, params.id]);
  //   await rejectPlayerIdCard({ player__id: `${params.id} ` });
  //   setLoadingList((d) => d.filter((l) => l !== params.id));
  // };
  const deletePlayerAction = async (params: DeleteOptions) => {
    setLoadingList((d) => [...d, params.id]);
    await deletePlayer({
      player__id: `${params.id} `,
      email: params.email,
    });
    setLoadingList((d) => d.filter((l) => l !== params.id));
  };

  const showDeleteModal = (params: { email: string; id: string }) => {
    setDeleteOptions({
      email: params.email,
      id: params.id,
      showConfirmDelete: true,
    });
  };

  const closeDeleteModal = () => {
    setDeleteOptions({
      email: "",
      id: "",
      showConfirmDelete: false,
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Nummber",
      width: 200,
    },
    {
      field: "idCard",
      headerName: "ID Card",
      width: 160,
      renderCell: (params: GridRenderCellParams) => (
        <a href={params.value} target="_blank" className="underline">
          <img src={params.value} alt="" />
        </a>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 200,
      getActions: (params: GridRowParams) => [
        <button
          disabled={
            (rejectLoading || acceptLoading || deleteLoading) &&
            loadingList.includes(params.id)
          }
          onClick={() => acceptPlayerAction(params)}
          className="border-2 w-[50%] min-w-[80px]  px-2 py-2 rounded-md border-green-600"
        >
          {(acceptLoading || deleteLoading || rejectLoading) &&
          loadingList.includes(params.id) ? (
            <CircularProgress
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          ) : (
            "Accept"
          )}
        </button>,
        <button
          disabled={
            (rejectLoading || acceptLoading || deleteLoading) &&
            loadingList.includes(params.id)
          }
          onClick={() =>
            showDeleteModal({ email: params.row.email, id: `${params.id}` })
          }
          className="border-2 w-[50%] min-w-[80px] px-2 py-2 rounded-md border-red-600"
        >
          {(rejectLoading || acceptLoading || deleteLoading) &&
          loadingList.includes(params.id) ? (
            <CircularProgress
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          ) : (
            "Delete"
          )}
        </button>,
      ],
    },
  ];
  const {
    error: getPlayerError,
    getAllPlayer,
    isLoading: getPlayerLoading,
    result,
  } = useGetAllPlayer();

  const {
    error: acceptError,
    acceptPlayerIdCard,
    isLoading: acceptLoading,
    result: acceptResult,
  } = useAcceptPlayerIdCard();

  const {
    error: rejectError,
    rejectPlayerIdCard,
    isLoading: rejectLoading,
    result: rejectResult,
  } = useRejectPlayerIdCard();

  const {
    error: deleteError,
    deletePlayer,
    isLoading: deleteLoading,
    result: deleteResult,
  } = useDeletePlayer();

  useEffect(() => {
    getAllPlayer({
      player_idcard_status: "3",
      pagination: {
        no_per_page: paginationModel.pageSize,
        page_no: paginationModel.page + 1,
      },
    });
  }, [rejectResult, acceptResult, paginationModel, deleteResult]);

  const rows: RowType[] = result?.data?.rows?.map(
    ({
      player__id: id,
      f_name: firstName,
      l_name: lastName,
      email,
      mobile: phoneNumber,
      idcard_img: idCard,
    }: any) => {
      return {
        id,
        firstName,
        lastName,
        email,
        phoneNumber,
        idCard,
      };
    }
  );

  return (
    <>
      <div className="overflow-auto h-full w-full">
        <DataGrid
          rows={rows || []}
          columns={columns}
          loading={getPlayerLoading}
          autoHeight
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={result?.data?.total_rows}
          pageSizeOptions={[5, 10, 15, 20]}
          onPaginationModelChange={setPaginationModel}
        />
      </div>
      <DeleteModal
        isOpen={deleteOptions.showConfirmDelete}
        setDeleteOptions={setDeleteOptions}
        onConfirm={() => deletePlayerAction(deleteOptions)}
      />
    </>
  );
};

export default RejectedTable;
