import React, { memo, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "services/AuthService";
import { getAccessToken } from "core/helper.service";
import { getUserType } from "core/helper";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  isOpen: boolean;
  closeSideBar: () => void;
}

const SideBarMenu: React.FC<IProps> = ({ isOpen, closeSideBar }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const logOut = async () => {
    await AuthService.logOut();
    localStorage.removeItem("tokenTackle");
    localStorage.removeItem("userType");
    navigate("/");
    closeSideBar();
  };

  const changeRoute = (route: string) => {
    navigate(route);
    closeSideBar();
  };

  // useEffect(() => {
  //   const userType = getUserType();
  //   if (!userType) navigate("/home");
  // }, [])

  return (
    <div className="md:hidden fixed top-0 right-0 z-20">
      <Transition
        show={isOpen}
        enter="transition ease-in-out duration-500 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="text-white w-[270px] h-screen bg-medBlack">
          <div className="flex flex-row">
            <div className="flex justify-center items-center w-[20%] text-white">
              <CloseIcon
                className="cursor-pointer text-white"
                style={{
                  width: "30px !important",
                  height: "30px !important",
                }}
                onClick={closeSideBar}
              />
            </div>
            <div className="flex w-[80%] pr-12 items-center justify-center my-5 cursor-pointer text-primeBlue">
              <span className="text-2xl" onClick={() => navigate("/")}>
                Tackle
              </span>
            </div>
          </div>

          <div className="flex flex-col items-center my-3">
            {/* <a
              className="my-3 text-xl border-b-2 w-full text-center border-b-medBlack hover:border-b-primeBlue"
              onClick={() => changeRoute("/")}
            >
              Home
            </a> */}

            {getAccessToken() ? (
              <>
                <a
                  className={
                    "my-4 text-xl border-b-2 w-full text-center border-b-medBlack " +
                    (pathname === "/main/players-list" && "text-yellow1")
                  }
                  onClick={() => changeRoute("/main/players-list")}
                >
                  Players List
                </a>
                <a
                  className={
                    "my-4 text-xl border-b-2 w-full text-center border-b-medBlack " +
                    (pathname === "/main/clubs-list" && "text-yellow1")
                  }
                  onClick={() => changeRoute("/main/clubs-list")}
                >
                  Clubs List
                </a>
              </>
            ) : (
              <>
                <a
                  className={
                    "my-4 text-xl border-b-2 w-full text-center border-b-medBlack " +
                    (pathname === "/club" && "text-yellow1")
                  }
                  onClick={() => changeRoute("/club")}
                >
                  Club
                </a>
                <a
                  className={
                    "my-4 text-xl border-b-2 w-full text-center border-b-medBlack " +
                    (pathname === "/player" && "text-yellow1")
                  }
                  onClick={() => changeRoute("/player")}
                >
                  Player
                </a>
                <a></a>
              </>
            )}
            <a
              className="my-4 text-xl border-b-2 w-full text-center border-b-medBlack text-white"
              href="https://www.goldensports.at/"
              target="_blank"
            >
              Golden Sports
            </a>
            <a
              className={
                "my-4 text-xl border-b-2 w-full text-center border-b-medBlack " +
                (pathname === "/about-us" && "text-yellow1")
              }
              onClick={() => changeRoute("/about-us")}
            >
              About Us
            </a>
            {getAccessToken() && (
              <a
                className="my-4 text-xl border-b-2 w-full text-center border-b-medBlack text-[#FF5454]"
                href="#"
                onClick={logOut}
              >
                Log Out
              </a>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default memo(SideBarMenu, (prev: IProps, next: IProps) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
