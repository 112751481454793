import { useState } from "react";
import axios from "axios";
import adminAPI from "../../constants/admin-API";
import useToken from "../useToken";
interface deleteType {
  player__id: string;
  email: string;
}
const useDeletePlayer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  const token = useToken();
  const deletePlayer = async (
    { player__id, email }: deleteType,
    callback?: any
  ) => {
    const formData = new FormData();
    formData.append("player__id", player__id);
    formData.append("email", email);
    try {
      setIsLoading(true);
      await axios
        .post(adminAPI.deletePlayer, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          setIsLoading(false);
          setResult(data);
          if (callback) {
            callback(data);
          }
          return data;
        });
    } catch (e: any) {
      setIsLoading(false);
      console.log(e);
      setError(e);
      return e;
    }
  };

  return {
    deletePlayer,
    result,
    isLoading,
    error,
  };
};
export default useDeletePlayer;
